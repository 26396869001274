@import './variables';

/* Mixins */
@mixin absoluteCenter($position: relative, $left: 50%, $right: 50%, $top: 50%, $bottom: 50%) {
    bottom: $bottom;
    left: $left;
    position: $position;
    right: $right;
    top: $top;
    @include transform(translate(-50%, -50%));
}


@mixin backgroundCover() {
    background-position: 50% 50%;
    background-size: cover;
}


@mixin backgroundContain() {
    background-position: 50% 50%;
    background-size: contain;
}


@mixin clearfix() {

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}


@mixin gradientBorder($position: top) {
    position: relative;

    &:before {
        content: "";
        height: 7px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2eff86+0,61ddff+70,2867ff+100 */
        background: #2eff86;
        /* Old browsers */
        background: -moz-linear-gradient(left, #2eff86 0%, #61ddff 70%, #2867ff 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #2eff86 0%, #61ddff 70%, #2867ff 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #2eff86 0%, #61ddff 70%, #2867ff 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2eff86', endColorstr='#2867ff', GradientType=1);

        /* IE6-9 */
        @if ($position =="top") {
            top: 0;
        }

        @if ($position =="bottom") {
            bottom: 0;
        }
    }
}


@mixin gradientBackground() {
    position: relative;

    &:before {
        bottom: 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2867ff+0,2eff86+100 */
        background: #2867ff;
        /* Old browsers */
        background: -moz-linear-gradient(-45deg, #2867ff 0%, #2eff86 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(-45deg, #2867ff 0%, #2eff86 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(135deg, #2867ff 0%, #2eff86 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2867ff', endColorstr='#2eff86', GradientType=1);
        /* IE6-9 fallback on horizontal gradient */
    }
}


@mixin anglePanelSide($angle: 12.5deg, $position: left) {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 30%;
        height: 100%;
        background-color: $unipol-light-blue;
        -webkit-transform: skewX($angle);
        -ms-transform: skewX($angle);
        transform: skewX($angle);

        @if ($position =="left") {
            -webkit-transform-origin: 0 0;
            -ms-transform-origin: 0 0;
            transform-origin: 0 0;
        }

        @if ($position =="right") {
            -webkit-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }
    }
}


@mixin gradientImageMask() {
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+100;Neutral+Density */
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000', endColorstr='#00000000', GradientType=1);
        /* IE6-9 */
    }
}


@mixin fixedAspectRatioContainer($width, $height) {
    height: 0;
    overflow: hidden;
    padding-top: $height/$width * 100%;
    position: relative;

    >* {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}


@mixin hortizontalAlign($position: relative, $left: 50%, $right: 50%) {
    left: $left;
    position: $position;
    right: $right;
    @include transform(translateX(-50%));
}


@mixin retina-image($image, $width, $height) {

    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
        background-image: url($image);
        background-size: $width $height;
    }
}


$image-path: 'images' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '_2x';

@mixin background-image($name, $width: false, $height: false) {
    background-image: url(#{$image-path}/#{$name}.svg);
    background-repeat: no-repeat;

    @if ($width and $height) {
        background-size: $width $height;
    }

    @else if ($width) {
        background-size: $width;
    }

    @else if ($height) {
        background-size: $height;
    }

    .no-svg & {
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3/2),
        only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (min-device-pixel-ratio: 1.5) {
            background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}


@mixin placeholderColour($placeholdercolour) {
    ::-webkit-input-placeholder {
        color: $placeholdercolour;
    }

    :-moz-placeholder {
        color: $placeholdercolour;
    }

    ::-moz-placeholder {
        color: $placeholdercolour;
    }

    :-ms-input-placeholder {
        color: $placeholdercolour;
    }
}


@mixin rotate($angle) {
    transform: rotate($angle);
    -webkit-transform: rotate($angle);
    -moz-transform: rotate($angle);
    -o-transform: rotate($angle);
    -ms-transform: rotate($angle);
}


@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}


@mixin transition($transitions...) {
    $unfoldedTransitions: ();

    @each $transition in $transitions {
        $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
    }

    -webkit-transition: $unfoldedTransitions;
    transition: $unfoldedTransitions;
}


@mixin underline($underlinewidth, $underlinecolor) {
    padding-bottom: rem-calc(15);
    position: relative;

    &:after {
        @include transform(translateX(-50%));
        background-color: $underlinecolor;
        bottom: 0;
        content: "";
        display: block;
        height: rem-calc(1);
        left: 50%;
        max-width: $underlinewidth;
        position: absolute;
        right: 50%;
        width: 100%;
    }
}


@mixin verticalAlign($position: relative, $top: 50%) {
    position: $position;
    top: $top;
    @include transform(translateY(-50%));
}


@mixin make-triangle($color: currentColor, $size: 4px, $direction: 'down') {
    border-color: transparent;
    border-style: solid;
    border-width: rem-calc($size);
    display: inline-block;
    vertical-align: middle;

    @if $direction =='down' {
        border-top-color: $color;
        margin-top: rem-calc($size);
    }

    @if $direction =='up' {
        border-bottom-color: $color;
        margin-top: rem-calc($size * -1);
    }

    @if $direction =='left' {
        border-right-color: $color;
        margin-left: rem-calc($size/2 * -1);
        margin-top: rem-calc($size/2 * -1);
    }

    @if $direction =='right' {
        border-left-color: $color;
        margin-left: rem-calc($size/2);
        margin-top: rem-calc($size/2 * -1);
    }
}


@mixin set-transparent {
    background-color: transparent;
    border-color: transparent;
}


// Need for IE10 and Safari 8
@mixin display($displayType) {
    @if ($displayType =="flexbox") {
        @error "I think you meant display(flex)";
    }

    @if ($displayType =="flex" or $displayType =="inline-flex") {
        display: -ms-#{$displayType}box;
        display: -webkit-#{$displayType};
        display: $displayType;
    }

    @else {
        display: $displayType;
    }
}


@mixin truncatePara($max-height) {
    overflow: hidden;
    position: relative;
    max-height: $max-height;
    margin-right: -1em;
    padding-bottom: rem-calc(15);
    padding-right: 1em;
}
