@include media-breakpoint-up(md) {

    .header .search-input,
    .datepicker,
    .monthpicker {
        height: 50px;
    }
}

#map-container-event {
    overflow: hidden;
    width: 100%;
    height: 400px;
    z-index: 10;
    background-color: $white;

    h1,
    h2,
    h3 {
        color: $primary;
    }
}

.map-container-affiliate {
    overflow: hidden;
    width: 100%;
    height: 400px;
    z-index: 10;
    background-color: $white;

    .top-bar {
        background-color: $primary;
        color: $white;
        text-align: center;
        width: 100%;
        height: 60px;
        overflow: visible;
        position: relative;
        z-index: 20;
        cursor: pointer;

        #choose-city {
            padding: 16px 50px;

            .city-show-button {
                margin: 0 20px;
            }
        }

        .choose-city-text {
            width: 50%;
            text-align: center;
        }
    }

    #choose-city-options {
        display: none;
        background-color: $primary;
        color: $white;
        top: 60px;
        left: 0;
        right: 0;
        z-index: 20;
        position: absolute;
        overflow: hidden;

        ul {
            margin: 10px 0;

            li {
                width: 100%;
                margin-bottom: 35px;
                list-style: none;

                a {
                    color: $white;
                    padding: 15px 30px 15px 30px;

                    &:hover {
                        background-color: $unipol-green;
                    }
                }
            }
        }
    }

    #city-info-wrapper {
        display: none;
        padding: 10px 30px;
        background-color: $white;
        color: $primary;
        top: 60px;
        left: 0;
        right: 60%;
        height: 400px;
        bottom: 0;
        z-index: 20;
        position: absolute;
        overflow: hidden;
        font-size: 1.4em;

        #city-info-data {
            padding: 0 5px;
        }

        &.show {
            display: block;
        }

        .data {
            text-align: left;
            margin-bottom: 20px;

            &.data-label {
                width: 100px;
            }

            &.field {
                color: $unipol-green;
            }
        }

        ul {
            li {
                width: 100%;
                border: 1px grey dashed;
                margin-bottom: 35px;

                a {
                    color: $white;
                    padding: 15px 30px 15px 30px;

                    &:hover {
                        background-color: $unipol-green;
                    }
                }
            }
        }
    }

    h1,
    h2,
    h3 {
        color: $primary;
    }
}

.footer-container {
    background-image: unset;
    margin: unset;
    padding: unset;
    background-repeat: unset;

    ul {
        overflow: unset;
        list-style: unset;
        font-size: unset;
        margin: unset;
        padding: unset;
        text-align: unset;

        li {
            height: unset;
            margin: unset;
        }
    }
}

.footer-logo {
    width: unset;
    max-width: unset;
    height: unset;
    display: unset;
    margin: unset;
}

.main-container ul {
    padding: unset;
}

.unipol-theme {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        font-family: $font-family-unipol;
    }

    h2 {}

    h3 {
        font-size: 1.5rem;
    }

    .btn,
    .btn:hover {
        border-bottom: 0;
    }
}

.search-container {
    float: none;
    margin: unset;
    padding: 0;
}

.well {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    /*.btn {
        @extend .btn;
        @extend .btn-green;
    }*/
    /*.btn-white {
        @include button-variant($white, $white);
    }*/
    /*.input-append {
        display: inline-block;
        float: right;

        form {
            display: inline-flex;
            margin-bottom: rem-calc(20);
        }
    }*/
    /*a {
        color: $primary;
    }*/
}

/*.form-horizontal {
    input {
        padding: .25rem .5rem;
    }

    @include media-breakpoint-up(md) {
        input {
            padding-bottom: .5rem;
            padding-top: .5rem;
        }
    }
}*/
.control-group {
    .controls {
        margin-bottom: 0;
    }
}

.EditingFormLabel {
    margin-top: 1rem;
}

.checkloader {
    display: none;
}

.CaptchaTable {

    td,
    th {
        padding: 0 !important;
    }
}

.CaptchaTextBox {
    border: 1px solid $unipol-light-blue;
}

.HiddenButton {
    display: none;
}

.qr-code {
    display: none !important;
}

.qr-code-advert {
    display: block !important;
}

.room-panel .qr-code-advert {
    display: none !important;
}

.advert-print-links {
    display: none !important;
}

.room-panel .advert-print-links {
    display: block !important;
}

.gallery-well {
    border: 1px solid rgba(0, 0, 0, .03);
    min-height: 360px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: rgba(0, 0, 0, .03);
    padding: 23px;

    .thumb-container {
        background: rgba(255, 255, 255, .8);
        position: relative;
        display: inline-block;
        margin: 10px 20px;
        vertical-align: top;
        border: 1px solid #acacac;
        padding: 6px;

        .btn {
            /*background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#fafafa),color-stop(1,#eee));
            background-image: -webkit-linear-gradient(top,#fafafa 0,#eee 100%);
            background-image: -moz-linear-gradient(top,#fafafa 0,#eee 100%);
            background-image: -o-linear-gradient(top,#fafafa 0,#eee 100%);
            background-image: -ms-linear-gradient(top,#fafafa 0,#eee 100%);
            background-image: linear-gradient(top,#fafafa 0,#eee 100%);
            -webkit-border-radius: 0;
            border-radius: 0;
            border: 1px solid #eee;*/
            text-decoration: none;
            /*padding: 4px 5px;
            text-align: center;
            color: #aaa;*/
            margin-top: 0;
            display: inline-block;
            vertical-align: central;
            /*height: 40px;*/
        }

        .thumb {
            width: 120px;
            /*height: 150px;*/
            position: relative;
            background: #ebebeb;
            /*padding: 5px;*/
            margin-bottom: 22px;
            text-align: center;

            &.larger {
                width: 180px;
                height: 180px;

                img {
                    max-width: 180px;
                    min-height: 180px;
                    max-height: 180px;
                }
            }

            &.admin {
                margin-bottom: 100px;
                text-align: center;

                .info {
                    /*bottom: 0;
                    left: 3px;
                    height: 28px;*/
                    padding-top: 10px;
                }
            }

            img {
                /*position: absolute;
                top: 0;
                left: 0;
                max-height: 100px;
                width: auto;*/
            }

            .info {
                /*position: absolute;
                bottom: -28px;
                left: 3px;
                height: 28px;
                line-height: 28px;
                overflow: visible;*/
            }
        }
    }
}

.card-deck {
    .slick-track {
        display: flex;
        flex-wrap: nowrap;
    }

    .slick-slide {
        margin: 0 5px;
        background-color: $white;
        box-sizing: border-box;
            height: auto;
        @media (max-width:539px) {
            margin: 0;
        }
    }

}

.dropzone.dz-clickable .dz-message {
    opacity: 1;
    filter: none;
    transition: opacity .3s ease-in-out;
    background-image: url('/src/general/img/dropzone-bg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 123px;

    .dz-button {
        opacity: 0;
    }
}
