.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.w-sm-25 {
    width: 25% !important;
}

.w-sm-30 {
    width: 30% !important;
}

.w-sm-33 {
    width: 33% !important;
}

.w-sm-40 {
    width: 40% !important;
}

.w-sm-50 {
    width: 50% !important;
}

.w-sm-66 {
    width: 66% !important;
}

.w-sm-75 {
    width: 75% !important;
}

.w-sm-100 {
    width: 100% !important;
}

@include media-breakpoint-up(md) {
    .w-md-25 {
        width: 25% !important;
    }

    .w-md-30 {
        width: 30% !important;
    }

    .w-md-33 {
        width: 33% !important;
    }

    .w-md-40 {
        width: 40% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-66 {
        width: 66% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }
}

.login-panel {
    .card {
        padding: rem-calc(20);
    }

    @include media-breakpoint-up(md) {
        .card {
            padding: rem-calc(32);
        }
    }

    @include media-breakpoint-up(lg) {
        .card {
            padding: rem-calc(80);
        }
    }
}