// Resets margins and paddings for headers, ul, ol and p
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
    margin: 0;
    padding: 0;
}

// Completely resets form items
// ----------------------------
// Super hard reset that removes all borders
// and radius of all form items.
// ============================
input[type=textbox],
textarea,
button {
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

select {
    border-radius: 0;
    outline: none;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

button {
    border: 0;
}

// Use border-box for everything
html {
    box-sizing: border-box;
}

/*body {
	overflow-y: scroll;
}*/
*,
*:before,
*:after {
    box-sizing: inherit;
}

// Set Image and Objects to have a max-width of 100%
embed,
iframe,
img,
object,
video {
    max-width: 100%;
}

.img-fluid {
    width: 100%;
}

p:empty {
    display: none;
}