aside {

    div:not(:last-child),
    article:not(:last-child) {
        margin-bottom: rem-calc(15);
    }
}

@include media-breakpoint-down(sm) {
    aside {
        margin-top: 1rem;
    }
}