@import '../../../../node_modules/bootstrap/scss/_functions';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../../node_modules/bootstrap/scss/_variables';

// Your variable overrides
//
// Font family
//
$font-family-unipol: "effra",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol" !default;
$font-family-base: $font-family-unipol;
$font-weight-light: 300;
$font-weight-semibold: 500;
$font-weight-bold: 700;

//
// Color system
//
$primary: #565450;
$white: #fff;
$unipol-green: #449a34; //rgba(68, 154, 52, 1)
$unipol-light-blue: #38a4c9;
$unipol-mid-blue: #0d556e;
$unipol-dark-blue: #1a1a25;
$unipol-off-white: #ebedf2;
$red: #f00;
$black: #000;
$grey: #999;
$yellow: #FFC107;


// Body
//
// Settings for the `<body>` element.
//
$body-bg: $unipol-off-white;
$body-color: $unipol-dark-blue;

// Links
//
// Style anchor elements.
//
$link-color: $unipol-light-blue;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

$border-color: rgba($black, .125);
$border-radius-0: 0;
$btn-border-radius: $border-radius-0;
$btn-border-radius-lg: $border-radius-0;
$btn-border-radius-sm: $border-radius-0;

$input-border-radius: $border-radius-0;
$input-border-radius-lg: $border-radius-0;
$input-border-radius-sm: $border-radius-0;

$font-size-base: 0.9375rem; // Typically `15px`


// Options
//
$enable-rounded: false;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1180px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$zindex-dropdown: 1000;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacers: map-merge((-1: ($spacer * -.25),
            -2: ($spacer * -.5),
            -3: $spacer * -1,
            -4: ($spacer * -1.5),
            -5: ($spacer * -3),
            -6: ($spacer * -5)),
        $spacers);

// Toggle Switch
//

$toggle-height: 24px;
$toggle-width: 48px;
$switch-size: 24px;

$anim-slight-bounce: cubic-bezier(0.34, 1.61, 0.7, 1);
$anim-speed-normal: 250ms;