.w-100 {
    width: 100%;
}

//fix bxslider error for FF where default height == 0
.bx-viewport {
    height: auto !important;
}

@import '../../components/sidebar/scss/index.scss';

@media (max-width:768px) {
    .gap-1{
        gap:1rem
    }
}

.is-hidden {
    display: none !important;
}
