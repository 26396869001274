@import '../../general/scss/settings/variables.scss';
@import '../../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../../node_modules/bootstrap/scss/mixins/gradients';
@import '../../../node_modules/bootstrap/scss/mixins/_box-shadow';
@import '../../../node_modules/bootstrap/scss/mixins/_hover';

button,
.btn {
    white-space: normal !important;

    &:hover {
        cursor: pointer;
    }
}

.btn {
    padding: .25rem .75rem;

    &-small {
        font-size: .85rem;
        padding: .125rem .325rem;
    }

    &-large {
        font-weight: $font-weight-semibold;
        padding: .25rem 1rem;
    }

    &-wide {
        padding-left: 2.25rem;
        padding-right: 2.25rem;
    }

    &-green {
        @include button-variant($unipol-green, $unipol-green);
    }

    &-grey,
    &-grey:visited {
        @include button-variant($grey, $grey);
        color: $white;
    }

    &-light-blue {
        @include button-variant($unipol-light-blue, $unipol-light-blue);
    }

    &-mid-blue {
        @include button-variant($unipol-mid-blue, $unipol-mid-blue);
    }

    &-dark-blue {
        @include button-variant($unipol-dark-blue, $unipol-dark-blue);
    }

    &-white {
        @include button-variant($white, $white);
    }
}

@include media-breakpoint-up(lg) {
    .btn {
        padding: .375rem 1rem;

        &-small {
            padding: .125rem .5rem;
        }

        &-large {
            font-size: 1.15rem;
            padding: .375rem 1.5rem;
        }

        &-vlarge {
            font-size: 1.5rem;
        }

        &-wide {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}

.btn-link {
    padding: 0;

    &-green {
        color: $unipol-green;
    }

    &-grey {
        color: $primary;
    }

    &:hover {
        /*border-bottom: 1px solid $primary;*/
        text-decoration: none;
    }
}

.btn-outline {

    &-white {
        @include button-outline-variant($white);

        &:hover {
            background-color: rgba(210, 210, 210, .25);
            color: $white;
        }
    }

    &-green {
        @include button-outline-variant($unipol-green);
    }

    &-grey {
        @include button-outline-variant($primary);
    }

    &-light-blue {
        @include button-outline-variant($unipol-light-blue);
    }

    &-mid-blue {
        @include button-outline-variant($unipol-mid-blue);
    }

    &-dark-blue {
        @include button-outline-variant($unipol-dark-blue);
    }
}

.btn-border {
    border: 1px solid;
    cursor: pointer;
    padding: 0 .25rem;
}

.cta-link {
    @extend .btn;
    @extend .btn-link;
    @extend .btn-link-green;
}

.cta-block {
    @extend .btn;
    @extend .btn-green;
    @extend .btn-large;
}


// Legacy Overrides
.call-to-action-container {
    .btn {
        @extend .cta-block;
    }
}

.FormButton {
    @extend .cta-block;
}

.SubmitButton {
    @extend .cta-block;

    &[value="OK"] {
        @extend .btn-green;
    }

    &[value="Cancel"] {
        @extend .btn-grey;
    }

    &[value="Preview"] {
        @extend .btn-light-blue;
    }
}

.section-inner {
    p .btn {
        @extend .cta-block;
    }
}

h2.social-follow {
    a {
        background-repeat: no-repeat;
        color: #4b4844;
        display: inline-block;
        font-size: 0.575em;
        font-weight: 400;
        line-height: 64px;
        margin-right: 18px;
        padding-left: 74px;
        padding-right: 18px;
    }


    .twitter {
        background-image: url("images/follow-twitter.png");

        &:hover {
            color: #39acc8;
        }
    }

    .facebook {
        background-image: url("images/follow-facebook.png");

        &:hover {
            color: #3852a4;
        }
    }
}

.button-wrap{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}