@media print {

    html,
    body {
        height: 100%;
        height: auto;
        margin: 0 0 0.5cm;
        padding: 0;
    }

    body {
        font-size: 10pt;
        font-family: "effra", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        line-height: 1.3;
        margin: 0;
    }

    /*div {
        page-break-inside: avoid;
    }*/

    p:empty {
        display: none;
    }

    header h1,
    header nav,
    footer {
        display: none;
    }

    .header-logo-mobile {
        display: none;
    }

    .header-logo {
        padding: 10px;
        text-align: center;

        img {
            width: 250px;
        }
    }


    #CMSHeaderDiv,
    #nav-open-btn,
    .nav-btn,
    .hide,
    .btn {
        display: none;
    }

    #photos-print-view {
        display: block;

        ul {
            list-style: none;
        }
    }

    img {
        max-height: 300px;
    }

    a {
        color: #0d556e;
        text-decoration: none;
    }

    /*p a:after {
        content: " (" attr(href) ")";
        font-size: 80%;
    }*/

    p a {
        -ms-word-wrap: break-word;
        word-wrap: break-word;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 14px;
    }

    blockquote,
    ul {
        margin: 0;
    }

    .searchbox,
    .search-container,
    #searchContent,
    #nav,
    .footer-row,
    .d-print-none,
    .simple-page-panel aside,
    .articleNav {
        display: none;
    }

    .d-print-inline,
    .d-print-inline div {
        display: inline;
    }

    .d-print-inline-block,
    .d-print-inline-block div {
        display: inline-block;
    }

    .d-print-block {
        display: block;
        float: none !important;
        margin: 0 !important;
    }

    .news-article-single {
        padding: 20px;
    }

    /* ADVER PANEL PRINT */
    .header-content {
        padding: 0 10px 0 10px;
    }

    .property-portfolio {
        padding: 0 10px 20px 10px;
    }

    .accordion-heading {
        border-bottom: 1px solid #ccc;
        font-size: 1.25em;
        font-weight: bold;
        margin-bottom: 5px;
        padding-top: 15px;
        padding-bottom: 5px;
    }

    .accordion-body {
        height: auto !important;
    }

    .epc-container {
        width: 50%;
    }

    .bx-wrapper,
    #photos-list {
        clear: both;
        display: block;
        max-width: 100% !important;
        width: 100% !important;

        li {
            float: left;
            margin: 10px 5px 0;
            width: auto !important;
        }

        img {
            max-height: 110px;
        }

        .bx-viewport {
            height: auto !important;

            .displayPropertyBanner {
                margin: 0;
                padding: 0;
                width: auto !important;
                -webkit-transform: none !important;
                -moz-transform: none !important;
                -ms-transform: none !important;
                -o-transform: none !important;
                transform: none !important;
            }
        }
    }

    #collapseFloorplans {
        li {
            display: inline-block;
        }

        img {
            max-height: 200px;
        }
    }

    .bx-controls,
    .location-amenities {
        display: none;
    }

    .main-container {
        width: 100%;
        margin-top: 10px;
    }

    .well {
        margin-bottom: 20px;
        background-color: #fff;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    }


    /* MAP LIST */



    /*.topbar {
        display: none;
    }*/
    #outer-wrap,
    #inner-wrap {
        width: auto;
        height: auto !important;
        overflow: visible;
    }

    .room-panel {
        display: none;
    }

    #advert-filters {
        display: none;
    }

    .tour,
    .loader,
    .modal,
    .mobile-only {
        display: none;
    }

    #property-list-nav {
        display: none;
    }

    .grid-panel,
    .map-container {
        display: none;
    }

    .list-panel {
        position: relative;
        width: 100% !important;
        height: 100%;
        display: block;
    }

    #advert-list {
        display: block;
    }

    .os-content-glue {
        display: none;
    }

    .topbar,
    #footer-bar {
        display: none;
    }



    ul#property-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        /*li:nth-of-type(5n) {
            page-break-after:always;
        }*/
        li {
            page-break-inside: avoid;
        }

        .advert-row {
            list-style-type: none;
            padding: 5px;
            /*width: 178px;
            height: 410px;*/
            border: 2px solid #CCCECF;
            border-radius: 4px;
            margin: 12px 0 0;
            min-height: 85px;
            position: relative;

            .property-image {
                height: 70px;
                width: 100px;
                position: absolute;
                left: 5px;
                top: 5px;

                img {
                    height: 70px;
                    max-width: 100px;
                }

                /*.property-image-circle {
                    height: 200px;
                    width: 200px;
                    border: none;
                }*/
            }

            .star-rating img {
                max-height: 100%;
            }

            .property-details {
                line-height: 1.25em;
                margin-left: 100px;
                margin-right: 10px;
                padding: 0 0 0 10px;
            }

            .property-address {
                clear: both;
                line-height: 1.25em;
                /*border-bottom: 1px solid #E8E8E8;*/
                font-size: 11pt;

                &:first-child {
                    margin-top: 25px
                }

                .star-rating {
                    height: auto;
                }
            }

            .property-list-price {
                line-height: 1em;
                font-size: 12pt;
                font-weight: bold;
                position: absolute;
                left: 115px;
                top: 5px;
            }

            .qr-code {
                display: block;
                position: absolute;
                right: 5px;
                top: 20px;

                img {
                    height: 60px;
                    width: 60px;
                    display: block;
                }
            }

            .bills-inclusive {
                display: none;
                position: absolute;
                right: 180px;
                top: 30px;

                img {
                    height: 20px !important;
                }
            }

            .amenities-list {
                display: block;
                margin-top: 5px;
            }

            /*.property-details:before {
                content: ' ';
                display: block;
                border: 1px solid #d0d0d0;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
                -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
            }*/
        }
    }


    .advert-list-panel {
        background-color: #fff;
        color: #343434;
        display: block;
        width: 100%;
        height: auto;
        overflow: visible;
    }

    .advert-list-filter-container {
        display: none;
    }

    #property-list-nav-sort {
        display: none;
    }

    .code-property,
    .unipol-property {
        background: none;
        display: block;
        height: 39px;
        right: 0;
        position: absolute;
        top: 48px;
        width: 39px;

        img {
            display: inline-block !important;
            height: 39px !important;
        }
    }

    .unipol-property {
        background: none;
        height: 41px;
        width: 54px;
        left: 45px;

        img {
            display: inline-block !important;
            height: 41px !important;
        }
    }

    .star-rating {
        display: inline-block;
        height: 35px;
    }

    .property-list-price .star-rating {
        display: block;
    }

    .landlord-contact {
        display: block;
        margin-top: 5px;
    }


    /******* Full advert ******/
    #btn-add-to-favourites,
    #btn-book-viewing,
    .tooltip-icon-container,
    .addthis_toolbox,
    hr {
        display: none !important;
    }

    .QRCode {
        height: 80px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .lnk-codes {
        float: left;
        padding-right: 20px;
    }

    .row-fluid,
    .row {
        clear: both;
        padding: 5px 0 8px;
    }

    .deposit-details {
        padding-bottom: 10px;
    }

    .span3,
    .span6,
    .span9,
    .col-3,
    .col-4,
    .col-6,
    .col-9 {
        float: left;
        margin-right: 10px;
    }

    .thumbnails {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .table td.first {
        width: 100px;
    }

    .event-article {

        .articleNav {
            display: none;
        }

        .columns {
            position: relative;
            display: table-row;

            .agtime {
                display: table-cell;
                width: 160px;
            }

            .agdesc {
                display: table-cell;
            }

            p.agborders {
                border-bottom: 1px solid #999;
                border-top: 1px solid #999;
            }
        }
    }

    .map-directions-link {
        display: none;
    }

    .module-container {
        display: none;
    }
}