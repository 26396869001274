body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.h1,
h1 {
    color: $unipol-dark-blue;
    font-size: 2rem;
}

.h2,
h2 {
    color: $unipol-dark-blue;
    font-size: 1.5rem;
}

.h3,
h3 {
    font-size: 1.25rem;
}

.h4,
h4 {
    font-size: 1rem;
}

ul {
    padding-left: 2.5rem;
}

li {
    margin-bottom: 0.5rem;
}

p.large,
ul.large>li,
span.large {
    font-size: 1.15rem;
    font-weight: $font-weight-semibold;
}

p.vlarge,
ul.vlarge>li,
span.vlarge {
    font-size: 2.2rem;
    font-weight: $font-weight-semibold;
    line-height: 1;
}

p.xlarge,
ul.xlarge>li,
span.xlarge {
    font-size: 2.8rem;
    font-weight: $font-weight-semibold;
    line-height: 1;
}

@include media-breakpoint-down(sm) {
    span.xlarge {
        font-size: 2.25rem;
    }
}


@include media-breakpoint-up(lg) {

    .h1,
    h1 {
        font-size: 2.25rem;
    }

    .h2,
    h2 {
        font-size: 1.75rem;
    }

    .h3,
    h3 {
        font-size: 1.5rem;
    }

    .h4,
    h4 {
        font-size: 1.25rem;
    }
}

.green {
    color: $unipol-green;
}

.grey {
    color: $primary;
}

.semi-bold {
    font-weight: $font-weight-semibold !important;
}

.bold {
    font-weight: $font-weight-bold !important;
}

.underline {
    text-decoration: underline;
}

/*blockquote {
	font-size: 1.25rem;
	margin: 1.25rem 2.5rem;
}*/