body {
    background: $body-bg;
    color: $body-color;
    padding-left: 0;
    padding-right: 0;
}

h1 {
    @extend .mb-3;
    @extend .pb-3;
}

h2 {
    @extend .my-2;
    @extend .pb-2;
}

h3 {
    @extend .my-2;
    @extend .pb-2;
}

h4 {
    @extend .my-2;
    @extend .pb-2;
}

h5 {
    @extend .my-2;
    @extend .pb-2;
}

h6 {
    @extend .my-1;
    @extend .pb-1;
}

p {
    @extend .my-2;
    @extend .pb-2;
}

.gradient-top {
    @include gradientBorder(top);
}

.gradient-bottom {
    @include gradientBorder(bottom);
}

/*#main {
	position: relative;
}*/